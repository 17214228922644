import React, { useState } from 'react'
import SlipDelete from '../slip/SlipDelete';
import SlipCopy from '../slip/SlipCopy';
import SlipGetSaved from '../slip/SlipGetSaved';
import fetchDataFromAPI from '../fetch/useFetchDataFromAPI';
import {roundDigits} from '../utils';
import { Text } from '../languages/Language';
import { getCurrency  } from '../constants';
import { URL as NEWURL } from '../constants';
import { VENDORID as NEWVENDORID,  getDecimalDigits } from '../constants';
import {toGermanDate} from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faReorder } from '@fortawesome/free-solid-svg-icons';


export default function Order(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = useState(null);
    const [value, setValue] = useState(false);
    const [dataAll, setDataAll] = useState(null);
    const [valueselect, setValueselect] = React.useState('Offen');

    const handleChange = (event) => {
        setValueselect(event.target.value);
        if (!sessionStorage.getItem('token')) {
            setData(null)
            setValue(!value)
        }
        else {
            if (value === true) fetchDataFromAPI(2010) //token, slipStatus
                .then(res => { setData(res); setValue(!value); console.log(res) });
            else {
                const url = NEWURL;

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Accept': 'application/json' },
                    body: JSON.stringify({
                        "serviceId": 2010,
                        "token": sessionStorage.getItem('token'),
                        "slipRef": sessionStorage.getItem('slipRef'),
                        "getAll": 1, //getAll gerekli mi?
                        "slipStatus": 2, //0: Aktif Sepet/Slip; 1: Kayitli Sepet/Slip; 2: Gönderilmis Sepet/Slip; 3: Silinen Sepet/Slip
                        "vendorId": NEWVENDORID
                    })
                };

                const fetchData = async () => {
                    try {
                        const response = await fetch(url, requestOptions);
                        const json = await response.json();
                        if (json.resCode === 200) {
                            setDataAll(json)
                            setValue(!value)
                        }
                        else return;
                    } catch (error) {
                        console.log("error", error);
                    }
                };
                fetchData();
            }
        }
    };

    function handleOrders() {
        setShowModal(true)
        if (sessionStorage.getItem('token')) {
            fetchDataFromAPI(2010) //token, slipStatus
                .then(res => { setData(res); console.log(res) });
        }
        else (setData(null))
        setValue(false)
    };

    function slipDeleteData(slipRef) {
        const tempData = data.dataSet.filter((x) => x.slipRef !== slipRef);
        setData({
            ...data,
            dataSet: tempData
        });
    }

    function handleClick() {
        props.onWait()
        setShowModal(false)
    }

    function handleSwitchBoxClick() {


        if (!sessionStorage.getItem('token')) {
            setData(null)
            setValue(!value)
        }
        else {
            if (value === true) fetchDataFromAPI(2010) //token, slipStatus
                .then(res => { setData(res); setValue(!value); console.log(res) });
            else {
                const url = NEWURL;

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Accept': 'application/json' },
                    body: JSON.stringify({
                        "serviceId": 2010,
                        "token": sessionStorage.getItem('token'),
                        "slipRef": sessionStorage.getItem('slipRef'),
                        "getAll": 1, //getAll gerekli mi?
                        "slipStatus": 2, //0: Aktif Sepet/Slip; 1: Kayitli Sepet/Slip; 2: Gönderilmis Sepet/Slip; 3: Silinen Sepet/Slip
                        "vendorId": NEWVENDORID
                    })
                };

                const fetchData = async () => {
                    try {
                        const response = await fetch(url, requestOptions);
                        const json = await response.json();
                        if (json.resCode === 200) {
                            setDataAll(json)
                            setValue(!value)
                        }
                        else return;
                    } catch (error) {
                        console.log("error", error);
                    }
                };
                fetchData();
            }
        }
    }

    return (
        <>
            <button
                className="flex h-10 items-center
                justify-center py-2
                text-sm font-bold  hover:opacity-75"
                type="button"
                onClick={() => handleOrders()}
            >
             <FontAwesomeIcon className='px-2 h-5 w-5 sm:text-menu-secondary-color' icon={faPaperPlane}/>
             <div title='Orders' className='md:hidden lg:block text-basm'><Text tid="Orders" /></div>
            </button>
            {showModal ? (
                <>

                    <div
                        className="text-slate-600 h-5/6 fade In justify-center items-center flex overflow-hidden top-16 fixed inset-0 z-50
                        outline-none focus:outline-none"
                    >
                        <div className="relative flex my-6 mx-auto w-5/6 h-full">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-2">

                                {/*header*/}
                                <div className="flex justify-between p-2 border-b border-solid border-slate-200 rounded-t items-center">
                                <button
                                        className="flex items-center justify-center ml-auto border-0 text-black opacity-50 float-right text-xl
                                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent h-7 w-7 text-xl inline-block align-text-top outline-none focus:outline-none">
                                            &#10006;
                                        </span>
                                    </button>
                                    <h3 className="text-lg font-semibold text-slate-700">
                                        <Text tid="Orders" />
                                    </h3>
                                    <div className='flex w-full justify-end mr-6'>
                                        <label>
                                            <select
                                                className='border-none focus:ring-0'
                                                valueselect={valueselect}
                                                onChange={handleChange}
                                            >
                                                <option value="Offen"><Text tid="Offen" /> </option>
                                                <option value="Gesendet"><Text tid="Gesendet" /> </option>
                                            </select>
                                        </label>
                                    </div>

                                </div>

                                {/*body*/}
                                <div className='flex flex-row w-full overflow-auto'>
                                    {!data ? <div className="flex w-96  h-96 items-center justify-center"><Text tid="NoOrders" /></div> : null}

                                    {data ?
                                        <div className='flex flex-row w-full overflow-auto text-sm '>
                                            <table className='overflow-auto w-full border-separate border border-slate-400 text-center table-auto'>
                                                <thead className='sticky top-0 bg-white'>
                                                    <tr>
                                                        <th className="border border-slate-300"><Text tid="Status" /></th>
                                                        <th className="border border-slate-300"><Text tid="TrackingNumber" /></th>
                                                        <th className="border border-slate-300"><Text tid="SlipDate" /></th>
                                                        <th className="border border-slate-300"><Text tid="FicheNumber" /></th>
                                                        <th className="border border-slate-300"><Text tid="ImportDate" /></th>
                                                        <th className="border border-slate-300"><Text tid="NetAmount" />  ({getCurrency()})</th>
                                                        <th className="border border-slate-300"><Text tid="TotalAmount" />  ({getCurrency()})</th>
                                                        <th ></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='overflow-y-auto '>
                                                    {data.recordCount && value === false ? data.dataSet?.map((record, index) => {
                                                        return (

                                                            <tr key={index} className="odd:bg-white even:bg-slate-200">


                                                                <td className=''>
                                                                    {record.slipStatus === 1 ? <p><Text tid="WaitingOrder" /></p> : null}
                                                                    {record.slipStatus === 2 ? <p><Text tid="SentOrder" /></p> : null}
                                                                </td>
                                                                <td>
                                                                    {record.slipCode}
                                                                </td>
                                                                <td>
                                                                    {toGermanDate(record.slipDate)}
                                                                </td>
                                                                <td>
                                                                    {record.erpFicheNo}
                                                                </td>
                                                                <td>
                                                                    {toGermanDate(record.erpImportDate)}
                                                                </td>

                                                                <td className='text-end pr-1'>
                                                                    {roundDigits(record.netTotal.toFixed(getDecimalDigits()))}
                                                                </td>
                                                                <td className='text-end pr-1'>
                                                                    {roundDigits(record.grossTotal.toFixed(getDecimalDigits()))}
                                                                </td>
                                                                <td className='flex gap-1 flex-row justify-around'>

                                                                    <SlipGetSaved
                                                                        slipRef={record.slipRef}
                                                                        saveActualBasket={true}
                                                                        onLoadSavedSlip={() => props.onLoadSavedSlip()}
                                                                        onClick={() => handleClick()}
                                                                    />
                                                                    <SlipDelete
                                                                        slipRef={record.slipRef}
                                                                        slipDeleteData={(slipRef) => slipDeleteData(slipRef)}
                                                                    />


                                                                </td>


                                                            </tr>
                                                        );
                                                    }
                                                    ) : null}

                                                    {dataAll && value === true ? dataAll.dataSet?.map((record, index) => {
                                                        return (

                                                            <tr key={index} className="odd:bg-white even:bg-slate-200">


                                                                <td className=''>
                                                                    {record.slipStatus === 1 ? <p><Text tid="Openstanding" /></p> : null}
                                                                    {record.slipStatus === 2 ? <p><Text tid="SentOrder" /></p> : null}
                                                                </td>
                                                                <td>
                                                                    {record.slipCode}
                                                                </td>
                                                                <td>
                                                                    {toGermanDate(record.slipDate)}
                                                                </td>
                                                                <td>
                                                                    {record.erpFicheNo}
                                                                </td>
                                                                <td>
                                                                    {toGermanDate(record.erpImportDate)}
                                                                </td>

                                                                <td>
                                                                    {roundDigits(record.netTotal.toFixed(2))}
                                                                </td>
                                                                <td>
                                                                    {roundDigits(record.grossTotal.toFixed(2))}
                                                                </td>
                                                                <td className='flex gap-1 flex-row justify-around'>

                                                                    <SlipCopy
                                                                        slipRef={record.slipRef}
                                                                        saveActualBasket={true}
                                                                        onLoadSavedSlip={() => props.onLoadSavedSlip()}
                                                                        onClick={() => handleClick()}
                                                                    />

                                                                </td>


                                                            </tr>
                                                        );
                                                    }
                                                    )
                                                        : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </>
            )
                : null
            }
        </>
    )
}
