//import { LockClosedIcon } from '@heroicons/react/solid'
import React, { useEffect } from 'react'
import Counter, { Counter3 } from "./Counter";
import Favorite from "./Favorite";
import { ReactComponent as NewIcon } from '../assets/pic/new.svg';
import { ReactComponent as DiscountIcon } from '../assets/pic/discount.svg';
import { ReactComponent as TickIcon } from '../assets/pic/tick.svg';
import discountImage from '../assets/pic/discount.png';
import newImage from '../assets/pic/new.png';
import tickImage from '../assets/pic/tick.png';
import {roundDigits} from '../utils';
import { Text } from '../languages/Language';
import fetchCampaignFromAPI from "../fetch/FetchCampaignFromAPI";
import {getCurrency ,  getDecimalDigits, IMAGEURL, FLAG_ITEM_ON_HAND} from '../constants';
import { IMAGETIMESTAMP } from '../App';

export default function ItemModal(props) {

    const [data, setData] = React.useState();

    useEffect(() => {
        fetchCampaignFromAPI(props.item.itemRef) //Fetch allCampaigns for a Product
            .then(json => {
                setData(json)
            })
    }, [props.item.itemRef]);

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed
                inset-0 z-50 outline-none focus:outline-none"
            >
                <div className= " flex justify-center w-4/5 sm:w-2/5 md:w-2/5 lg:w-2/3 xl:w-3/5 " >
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg bg-white outline-none focus:outline-none
                    h-full flex flex-col"
                    >
                        {/*header*/}
                        <div
                            className="fade In flex items-center justify-start p-1 border-b
                        border-solid border-slate-200 rounded-t"
                        >
                            <button
                                className="p-1 bg-transparent flex items-center border-0
                                 text-slate-900 opacity-50 float-right text-3xl leading-none
                                 font-semibold outline-none focus:outline-none"
                                onClick={() => props.onSetModal(false)}
                            >
                                <span
                                    className="bg-transparent text-slate-900 h-6 w-6 text-lg block
                                outline-none focus:outline-none items-center flex justify-center">
                                    &#10006;

                                </span>
                            </button>
                            <h3
                                className="text-lg font-semibold text-slate-700 p-1"
                            >
                                &#10140; {props.item.itemCategoryName}
                                &#10140; {props.item.itemSubCategoryName}
                            </h3>

                        </div>
                        {/*body*/}
                        <div
                            className='flex flex-col lg:flex-row justify-center p-1 text-xxs '
                        >
                            <div
                    className={`
                ${props.item.itemAmount
                            ? "border-company-secondary-color"
                            : "border-slate-300"
                        }
                bg-white  flex flex-wrap flex-col border-2 rounded-xl relative m-2
                `}
                >
                                        <div className="flex justify-center items-center rounded-md border-2 bg-white
                                          border-slate-200 absolute bottom-[-7px] left-[-11px] z-[1]">
                                          <div
                                          className={
                                            (props.item.itemOnHand === 0
                                              ? "h-4 w-4 bg-red-500 rounded-full m-1"
                                              : "h-4 w-4 bg-green-500 rounded-full m-1")
                                          }>
                                            </div>
                                        </div>

                                        <div className='absolute top-[-15px] right-[-15px] z-[1]'>
                                                            <div
                                                    className={`
                                                ${!props.item.itemOnHand && FLAG_ITEM_ON_HAND
                                                            ? "hidden"
                                                            : ""}
                                                `}
                                                >
                                                    {sessionStorage.getItem('token')
                                                        ? <Counter3
                                                            basket={false}
                                                            count={props.item.itemAmount}
                                                            onClickIncrement={() => props.onAdd(props.item)}
                                                            onClickDecrement={() => props.onRemove(props.item)}
                                                        />
                                                        : null
                                                    }
                                                </div>
                                        </div>

                                        <div className='absolute bottom-[20px] left-[-13px] z-[1]'>
                                        <Favorite
                                                className=""
                                                basket={true}
                                                itemRef={props.item.itemRef}
                                                itemFavorite={props.item.itemFavorite}
                                                item={props.item}
                                                onAddAllFavorites={props.onAddAllFavorites}
                                                onRemoveAllFavorites={props.onRemoveAllFavorites}
                                            />
                                        </div>

                                        <div className='absolute bottom-[-5px] left-[17px] z-[1]'>
                                                {props.item.itemSold === 1
                                            ? <div
                                                className={` ${props.item.itemAmount
                                                    ? "border-company-primary-color"
                                                    : "border-slate-300"
                                                    }
                                            flex flex-row items-center pt-1.5 ml-1 justify-between text-company-primary-color w-6 h-6
                                             rounded-lg`}
                                            >
                                               <img src={tickImage}></img>
                                            </div>
                                            :null
                                        }
                                        </div>

                                        <div className='absolute bottom-[-8px] left-[48px] z-[1] w-6 h-6'>
                                        {props.item.itemNew === 1
                                            ? <img src={newImage}></img>
                                            :null
                                        }
                                        </div>

                                        <div className='absolute bottom-[-7px] left-[75px] z-[1]'>
                                        {props.item.itemCampaign === 1
                                            ? <div
                                            className=" font-bold flex flex-row items-center
                                        text-red-600 pt-0.5
                                        w-6 h-6
                                         rounded-lg">

                                        <img src={discountImage}></img>
                                        </div>
                                        :null
                                        }
                                        </div>


                    <div
                        className="flex-col flex sm:h-72 lg:h-96  sm:w-72 lg:w-96 p-1 justify-center"
                    >

                        <div
                            className="flex  sm:h-72 lg:h-96 sm:max-h-72 rounded bg-white"
                        >
                            <img
                                alt=""
                                onClick={() => props.onSetModal(false)}
                                src={IMAGEURL
                                    + "/catalog/"
                                    + props.item.itemCode
                                    + ".jpg?"+ IMAGETIMESTAMP
                                }
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src = "noImage.jpg";
                                }}
                                title={props.item.itemName1}
                                className="p-1 w-full max-h-72 lg:max-h-96 object-contain"
                            />






                        </div>
                    </div>
                </div>

                            <div
                                className="h-auto  bg-slate-200 rounded-xl p-4 m-1 text-xxs lg:w-1/2"
                            >
                                <div className="text-slate-900 flex-col flex w-full font-bold gap-4" >

                                        <div className='flex flex-row justify-between'>
                                        <div
                                        className={` ${props.item.itemAmount
                                            ? "border-company-primary-color"
                                            : "border-slate-300"
                                            }
                                            text-xxs flex flex-row items-center p-1 justify-between
                                            text-slate-400 w-auto h-7 bg-white border rounded-lg`
                                        }
                                    >
                                        <span>
                                            {props.item.itemCode}
                                        </span>


                                    </div>

                                        </div>
                                    <div className=''>
                                        {props.item.itemName1}
                                    </div>
                                    <div className="text-slate-400">
                                        {props.item.itemName2}
                                    </div>


                                    <div className='flex flex-row justify-start'>
                                        <div className='w-1/2'><Text tid="SalesUnit" /></div>
                                        <div className='text-left'>
                                        {
                                            props.item.itemUnitCode
                                            + "("
                                            + props.item.itemMainUnitVpe
                                            + ")"
                                        }
                                        </div>

                                    </div>


                                    {sessionStorage.getItem('token')
                                        ? <div className='h-auto'>
                                            <div className='flex flex-row justify-start'>
                                            <div className='w-1/2'><Text tid="VAT" /></div>
                                            <div
                                                className="text-slate-600"
                                            >
                                                 {props.item.itemVat} %
                                            </div>
                                            </div>

                                            <div className="mt-3" >
                                                <div className='flex flex-row justify-start'>
                                                <div className='w-1/2'><Text tid="Price" /></div>
                                                <div className=''>
                                                <div
                                                    className={` ${props.item.itemCampaignPrice
                                                        ? "line-through"
                                                        : ""} font-bold text-company-primary-color  text-xxs`
                                                    }
                                                >
                                                    {roundDigits(props.item.itemPrice.toFixed(getDecimalDigits()))}&nbsp; {getCurrency()}
                                                </div>
                                                <div
                                                    className="flex flex-row items-center text-xxs"
                                                >

                                                    {props.item.itemCampaignPrice > 0
                                                        ?  <div className="
                                                        font-bold flex
                                                        flex-row
                                                        items-center
                                                        w-26 h-7">
                                                                <span
                                                                    className='ml-2'
                                                                >
                                                                    {roundDigits(props.item.itemCampaignPrice.toFixed(getDecimalDigits()))
                                                                    }&nbsp;
                                                                    {getCurrency()}
                                                                </span>

                                                            </div>
                                                        :  null

                                                    }
                                                </div>
                                                </div>
                                                </div>
                                            </div>


                                        </div>
                                        :null
                                    }

                                    <div>
                                        {
                                            data && data.dataSet

                                            ? <div
                                                className='flex flex-row w-full overflow-auto'
                                            >
                                                <table
                                                    className='overflow-auto w-full border-separate
                                                        border border-slate-400 text-center table-auto'
                                                >
                                                    <thead className='sticky top-0 bg-white'>
                                                        <tr>
                                                            <th className="border border-slate-300"><Text tid="Preis" /></th>
                                                            <th className="border border-slate-300">%</th>
                                                            <th className="border border-slate-300"><Text tid="Min" /></th>
                                                            <th className="border border-slate-300"><Text tid="Max" /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='overflow-y-auto '>
                                                        {
                                                            data
                                                            && data.dataSet?.map((item, itemIndex) => (
                                                                <tr
                                                                    key={itemIndex}
                                                                    className="odd:bg-white even:bg-slate-200 text-xxs"
                                                                >

                                                                    <td className=''>
                                                                        {item.CampaignPrice}
                                                                    </td>
                                                                    <td className=''>
                                                                        {
                                                                            ((props.item.itemPrice - item.CampaignPrice) /
                                                                                (props.item.itemPrice / 100)).toFixed(0)
                                                                        }
                                                                    </td>
                                                                    <td className=''>
                                                                        {item.MinAmount}
                                                                    </td>
                                                                    <td className=''>
                                                                        {item.MaxAmount}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    :null
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="opacity-50 fixed inset-0 z-40 bg-black"
            >
            </div>
        </>
    )
}
