import React, { useState } from 'react'
import BillToPDF from './BillToPDF';
import fetchDataFromAPI from '../fetch/useFetchDataFromAPI';
import { Text } from '../languages/Language';
import { getCurrency } from '../constants';
import { URL as NEWURL } from '../constants';
import { VENDORID as NEWVENDORID, getDecimalDigits } from '../constants';
import  {toGermanDate, roundDigits } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

export default function Bill() {
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = useState(null);
    const [value, setValue] = useState(false);
    const [valueselect, setValueselect] = React.useState('Offen');
    const [errorText, setErrorText] = useState("")
    const [showError, setShowError] = useState(false);

    const handleChange = (event) => {
        setValueselect(event.target.value);
        setValue(!value)
        if (!sessionStorage.getItem('token')) {
            setData(null)

            console.log("notoken")
        }
        else {
            console.log("token")
            if (value === true) fetchDataFromAPI(1221) //token, slipStatus
                .then(res => { console.log("token and true"); setData(res); setValue(false); console.log(res) });
            else if (value === false) {
                console.log("token and false");
                const url = NEWURL;

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Accept': 'application/json' },
                    body: JSON.stringify({
                        "serviceId": 1221,
                        "token": sessionStorage.getItem('token'),
                        "slipRef": sessionStorage.getItem('slipRef'),
                        "invoiceStatus": 1, //getAll gerekli mi?
                        "slipStatus": 2, //0: Aktif Sepet/Slip; 1: Kayitli Sepet/Slip; 2: Gönderilmis Sepet/Slip; 3: Silinen Sepet/Slip
                        "vendorId": NEWVENDORID
                    })
                };
                console.log(requestOptions)

                const fetchData = async () => {
                    try {
                        const response = await fetch(url, requestOptions);
                        const json = await response.json();
                        if (json.resCode === 200) {

                            setData(json)
                            setValue(true)
                        }
                        else return;
                    } catch (error) {
                        console.log("error", error);
                    }
                };
                fetchData();
            }
        }
    };

    function handleBills() {
        if (sessionStorage.getItem('token'))
            fetchDataFromAPI(1221) //token, getAll:1
                .then(res => {
                    setData(res);
                });
        else (setData(null))
        setShowModal(true)
        setValue(false)
    }

    const invoiceAmount = data?.dataSet?.length > 0 ? data?.dataSet?.reduce((a, c) =>
        a + c.invoiceAmount, 0) : 0;

    const paid = data?.dataSet?.length > 0 ? data?.dataSet?.reduce((a, c) =>
        a + c.coveredAmount, 0) : 0;

    const open = data?.dataSet?.length > 0 ? data?.dataSet?.reduce((a, c) =>
        a + c.remainingAmount, 0) : 0;

    return (
        <>
            <button
                className="flex h-10  items-center
                justify-center  py-2
                text-sm font-bold hover:opacity-75"
                type="button"
                onClick={() => handleBills()}
            >
             <FontAwesomeIcon className='px-2 h-5 w-5 text-menu-secondary-color' icon={faFileInvoice}/>
             <div title='Invoices' className='md:hidden lg:block text-sm'><Text tid="Invoices" /></div>
            </button>

            {showModal ? (
                <>
                    <div
                        className="text-slate-600 h-5/6 fade In justify-center items-center flex overflow-hidden top-16 fixed inset-0 z-50
                        outline-none focus:outline-none"
                    >
                        <div className="flex relative w-5/6 my-6 mx-auto  h-full">
                            {/*content*/}
                            <div className="overflow-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white
                            outline-none focus:outline-none p-2">
                                {/*header*/}
                                <div className="sticky top-0 flex items-start justify-between p-2 border-b border-solid
                                border-slate-200 rounded-t w-full h-18">
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl
                                        leading-none outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black h-6 w-6 text-lg block outline-none
                                        focus:outline-none">
                                            &#10006;
                                        </span>
                                    </button>
                                    <h3 className="text-lg  text-slate-700 mt-1">
                                        <Text tid="Invoices" />
                                    </h3>
                                    <div className='flex w-full justify-end mr-6'>
                                        <label>
                                            <select
                                                className='border-none focus:ring-0'
                                                valueselect={valueselect}
                                                onChange={handleChange}
                                            >
                                                <option value="Offen"><Text tid="Offen" /> </option>
                                                <option value="Bezahlt"><Text tid="Bezahlt" /> </option>
                                            </select>
                                        </label>
                                    </div>

                                </div>
                                {/*body*/}
                                <div className='flex flex-row w-full overflow-auto'>
                                    {!data ? <div className="h-96"></div> : null}
                                    {data && value === false
                                        ?
                                        <div className='flex flex-col w-full overflow-auto text-sm '>
                                            <table className='overflow-auto w-full border-separate text-center
                                             table-auto '>
                                                <thead className='sticky top-0 bg-white'>
                                                    <tr>
                                                        <th className="border border-slate-300"><Text tid="InvoiceDate" /></th>
                                                        <th className="border border-slate-300"><Text tid="DueDate" /></th>
                                                        <th className="border border-slate-300"><Text tid="DueDays" /></th>
                                                        <th className="border border-slate-300"><Text tid="InvoiceNumber" /></th>
                                                        <th className="border border-slate-300 w-64"><Text tid="InvoiceType" /></th>
                                                        <th className="border border-slate-300 text-end pr-1"><Text tid="InvoiceAmount" /> ({getCurrency()}) {roundDigits(invoiceAmount?.toFixed(getDecimalDigits()))}</th>
                                                        <th className="border border-slate-300 text-end pr-1"><Text tid="PaidAmount" /> ({getCurrency()}) {roundDigits(paid?.toFixed(getDecimalDigits()))}</th>
                                                        <th className="border border-slate-300 text-end pr-1"><Text tid="OpenAmount" /> ({getCurrency()}) {roundDigits(open?.toFixed(getDecimalDigits()))}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='overflow-y-auto '>
                                                    {data.dataSet?.map((record, index) => {
                                                        return (
                                                            <tr key={index} className="odd:bg-white even:bg-slate-200">
                                                                <td>
                                                                    {toGermanDate(record.invoiceDate)}
                                                                </td>
                                                                <td >
                                                                    {toGermanDate(record.dueDate)}
                                                                </td>
                                                                <td>
                                                                    {record.dueDay}
                                                                </td>
                                                                <td className='text-start'>
                                                                    {record.invoiceNr}
                                                                </td>
                                                                <td className='text-start'>
                                                                    {record.invoiceType}
                                                                </td>
                                                                <td className='text-end pr-1'>
                                                                    {roundDigits(record.invoiceAmount.toFixed(getDecimalDigits()))}
                                                                </td>
                                                                <td className='text-end pr-1'>
                                                                    {roundDigits(record.coveredAmount.toFixed(getDecimalDigits()))}
                                                                </td>
                                                                <td className='text-end pr-1'>
                                                                    {roundDigits(record.remainingAmount.toFixed(getDecimalDigits()))}
                                                                </td>
                                                                <td className='md:flex md:justify-center '>
                                                                    <BillToPDF
                                                                    setError={(bool)=>setErrorText(bool)}
                                                                    showError={(bool)=>setShowError(bool)}
                                                                    invoiceNr={record.invoiceNr} />
                                                                    </td>
                                                            </tr>
                                                        );
                                                    }
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className='sticky bottom-0 bg-white'>
                                                <div className='flex'>
                                                    <p><Text tid="RecordCount" />: {data.recordCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {data && value === true
                                        ? <div className='flex flex-col w-full overflow-auto text-sm '>
                                            <table className='overflow-auto w-full border-separate text-center
                                             table-auto '>
                                                <thead className='sticky top-0 bg-white'>
                                                    <tr>
                                                        <th className="border border-slate-300"><Text tid="InvoiceDate" /></th>
                                                        <th className="border border-slate-300"><Text tid="DueDate" /></th>
                                                        <th className="border border-slate-300"><Text tid="DueDays" /></th>
                                                        <th className="border border-slate-300"><Text tid="InvoiceNumber" /></th>
                                                        <th className="border border-slate-300 w-64"><Text tid="InvoiceType" /></th>
                                                        <th className="border border-slate-300 text-end pr-1"><Text tid="InvoiceAmount" /> ({getCurrency()}) {roundDigits(invoiceAmount?.toFixed(getDecimalDigits()))}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='overflow-y-auto '>
                                                    {data.dataSet?.map((record, index) => {
                                                        return (
                                                            <tr key={index} className="odd:bg-white even:bg-slate-200">

                                                                <td>
                                                                    {toGermanDate(record.invoiceDate)}
                                                                </td>
                                                                <td >
                                                                    {toGermanDate(record.dueDate)}
                                                                </td>
                                                                <td>
                                                                    {record.dueDay}
                                                                </td>
                                                                <td className='text-start'>
                                                                    {record.invoiceNr}
                                                                </td>
                                                                <td className='text-start'>
                                                                    {record.invoiceType}
                                                                </td>
                                                                <td className='text-end pr-1'>
                                                                    {roundDigits(record.invoiceAmount.toFixed(getDecimalDigits()))}
                                                                </td>
                                                                <td className='flex justify-center'>
                                                                    <BillToPDF
                                                                    setError={(bool)=>setErrorText(bool)}
                                                                    showError={(bool)=>setShowError(bool)}
                                                                    invoiceNr={record.invoiceNr} />
                                                                    </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <div className='sticky bottom-0 bg-white'>
                                                <div className='flex'>
                                                    <p><Text tid="RecordCount" />: {data.recordCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </>
            )
                : null
            }

{
        showError && (
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
                overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className="text-black flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center"
                                >
                                    <Text tid="Error" />
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="relative p-6 flex-auto"
                      >
                          <div
                              className="text-center text-black">
                              {errorText}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                    onClick={() => setShowError(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        )
      }
        </>
    )
}
